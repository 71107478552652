@import '../public/fonts/gilroy/fonts.css'

@tailwind base
@tailwind components
@tailwind utilities

html
  font-size: 62.5%
  overflow-x: hidden

main
  overflow-x: hidden

html,
body
  padding: 0
  margin: 0
  height: 100%
  width: 100%
  display: flex
  flex-direction: column

#__next
  display: flex
  flex-direction: column
  height: 100%
  width: 100%

a
  color: inherit
  text-decoration: none

*
  box-sizing: border-box
