/* Regular */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Regular.ttf') format('truetype'),
        url('./Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Medium */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Medium.ttf') format('truetype'),
        url('./Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Thin */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Thin.ttf') format('truetype'),
        url('./Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* UltraLightItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'),
        url('./Gilroy-UltraLightItalic.ttf') format('truetype'),
        url('./Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-UltraLightItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/* Light */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Light.ttf') format('truetype'),
        url('./Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* SemiBold */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-SemiBold.ttf') format('truetype'),
        url('./Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* UltraLight */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-UltraLight.ttf') format('truetype'),
        url('./Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/* Bold */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Bold.ttf') format('truetype'),
        url('./Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* ExtraBold */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

/* Black */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Black.ttf') format('truetype'),
        url('./Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* ThinItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-ThinItalic.ttf') format('truetype'),
        url('./Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/* LightItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-LightItalic.ttf') format('truetype'),
        url('./Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-LightItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/* MediumItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-MediumItalic.ttf') format('truetype'),
        url('./Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/* BoldItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-BoldItalic.ttf') format('truetype'),
        url('./Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

/* BlackItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-BlackItalic.ttf') format('truetype'),
        url('./Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/* Heavy */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-Heavy.ttf') format('truetype'),
        url('./Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* HeavyItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-HeavyItalic.ttf') format('truetype'),
        url('./Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-HeavyItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/* RegularItalic */

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./Gilroy-RegularItalic.ttf') format('truetype'),
        url('./Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./Gilroy-RegularItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
